<template>
  <div>
    <AppForm
      :id="id"
      size="lg"
      :submit-label="$t('choose_image') || 'Choose Image'"
      @hide="$emit('hide')"
      @submit.prevent="handleSubmit"
    >
      <!--  -->
      <TableSearch
        initial-selected="title_ar"
        :search-by-options="searchByOptions"
        @search="fetchGalleryHandler"
      />

      <b-overlay
        :show="isLoading"
        class="
          gallery-section
          my-2
          d-flex
          flex-wrap
          border-top border-bottom
          py-2
        "
      >
        <button
          type="button"
          class="border img-thumbnail bg-transparent p-0 mr-2 add-new-image"
          @click="openGalleryForm"
        >
          <feather-icon icon="PlusIcon" height="150" size="50" />
          <p>{{ $t("add_new_image") || "Add New Image" }}</p>
        </button>
        <button
          v-for="(img, index) in images"
          :key="index"
          :data-test="`image-${index}`"
          type="button"
          class="border-0 bg-transparent p-0 mr-2"
          @click="selectImageHandler(img)"
        >
          <figure
            class="
              mb-0
              p-50
              border
              rounded
              img-thumbnail
              d-inline-block
              transition--fast
            "
            :class="
              selectedImage && img.uuid === selectedImage.uuid
                ? 'bg-primary text-white'
                : ''
            "
          >
            <!-- :alt="isRtl ? img.alt_ar : img.alt" -->
            <b-img-lazy
              :src="img.path"
              :alt="img.alt_ar"
              height="150"
              width="150"
              class="rounded object-fit-contain"
            />
            <figcaption class="mb-0 mt-50 text-wrap">
              {{ img.title_ar }}
              <!-- <br /> -->
              <!-- {{ img.title }} -->
            </figcaption>
          </figure>
        </button>
        <b-alert
          v-if="!isLoading && !images.length"
          show
          class="p-1 m-auto"
          variant="warning"
        >
          <span class="text-center">
            {{ $t("no_images_found") || "No Images Found" }}
          </span>
        </b-alert>
      </b-overlay>
      <TablePagination
        :meta="imagesMeta"
        @change-pagination="changePaginationHandler"
      />
      <!--  -->
    </AppForm>
    <GalleryForm
      v-if="userCan('gallery-edit') || userCan('gallery-add')"
      :id="'article-gallery-crud-form'"
      :initial-data="null"
      @uploaded="selectImageHandler"
      @hide="hideGalleryForm"
    />
  </div>
</template>

<script>
import { BImgLazy, BOverlay, BAlert } from "bootstrap-vue";
import { AppForm } from "@/components/form/index";
import TableSearch from "@/components/table/TableSearch.vue";
import GalleryForm from "@/views/gallery/partials/GalleryForm.vue";
import userCan from "@/mixins/UserCan";
import TablePagination from "@/components/table/TablePagination.vue";

export default {
  name: "GalleryModal",
  components: {
    AppForm,
    TablePagination,
    GalleryForm,
    BImgLazy,
    BOverlay,
    TableSearch,
    BAlert,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "gallary-modal",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    images() {
      return this.$store.state.gallery.data;
    },
    imagesMeta() {
      return this.$store.state.gallery.meta;
    },
    isLoading() {
      return this.$store.state.gallery.isLoading.fetching;
    },
    isRtl() {
      return window.localStorage.getItem("language") === "ar";
    },
  },
  data() {
    return {
      selectedImage: null,
      searchByOptions: [
        {
          text: this.$t("title") || "Title",
          value: "title_ar",
        },
      ],
    };
  },
  created() {
    this.fetchGalleryHandler();
  },
  methods: {
    userCan,
    handleSubmit() {
      this.$emit("select", this.selectedImage);
      this.$bvModal.hide(this.id);
    },
    openGalleryForm(item = null) {
      // if (item) this.activeItem = item;
      this.$bvModal.show("article-gallery-crud-form");
    },
    changePaginationHandler(page = 1) {
      this.fetchGalleryHandler({ page });
    },
    hideGalleryForm() {
      this.activeItem = null;
    },
    selectImageHandler(img) {
      this.selectedImage = img;
      // this.$emit("select");
    },
    fetchGalleryHandler(searchQuery = null) {
      this.$store.dispatch("gallery/fetchData", searchQuery);
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-section > button {
  width: calc(150px + 1rem);
  margin-bottom: calc(1.5rem / 2);
  margin-top: calc(1.5rem / 2);
}
</style>
