<template>
  <b-card>
    <div class="col-md-6">
      <AppForm
        id="settingsForm"
        :errors-bag="errorsBag"
        :is-loading="isLoading"
        :success-title="$t('success') || 'Success'"
        :success-message="successMessage"
        plain
        @submit.prevent="handleSubmit"
      >
        <!--  -->
        <h3>{{ $t("general_settings") || "General Settings" }}</h3>
        <AppInput
          id="app_name"
          v-model="formData.app_name"
          type="text"
          :label="$t('app_name') || 'App Name'"
        />

        <AppInput
          id="app_name_ar"
          v-model="formData.app_name_ar"
          type="text"
          :label="$t('app_name_ar') || 'App Name in arabic'"
        />

        <fieldset class="form-group mt-2">
          <legend for="logo-image" class="col-form-label pt-0">
            {{ $t("logo_image_id") || "Logo Image" }}
          </legend>
          <img
            v-if="formData.logo_image_id"
            width="100%"
            data-test="image"
            height="200"
            class="object-fit-contain mb-2"
            :src="formData.logo_image_id.path"
            :alt="formData.logo_image_id.alt_ar"
          />
          <AppButton
            variant="outline-secondary"
            data-test="choose_image_btn"
            class="w-100"
            type="button"
            :label="
              formData.logo_image_id
                ? $t('change_image') || 'Change Image'
                : $t('choose_image') || 'Choose Image'
            "
            @click="openGallaryModal"
          />
        </fieldset>

        <AppTextarea
          id="meta_description"
          v-model="formData.meta_description"
          data-test="meta_description"
          rows="3"
          max-rows="8"
          :label="$t('meta_description') || 'Description'"
          :placeholder="$t('meta_description') || 'Description'"
        />

        <AppTextarea
          id="meta_description_ar"
          v-model="formData.meta_description_ar"
          data-test="meta_description_ar"
          rows="3"
          max-rows="8"
          :label="$t('meta_description_ar') || 'Description ar'"
          :placeholder="$t('meta_description_ar') || 'Description ar'"
        />

        <AppInput
          id="email"
          v-model="formData.email"
          type="email"
          :label="$t('email') || 'email'"
        />

        <AppInput
          id="mobile"
          v-model="formData.mobile"
          type="text"
          :label="$t('mobile') || 'mobile'"
        />

        <AppInput
          id="land_line"
          v-model="formData.land_line"
          type="text"
          :label="$t('land_line') || 'land_line'"
        />

        <hr />
        <section>
          <h4>{{ $t("social_media") || "Social Media" }}</h4>
          <AppInput
            id="facebook_link"
            v-model="formData.facebook_link"
            type="url"
            :label="$t('facebook_link') || 'facebook_link'"
          />

          <AppInput
            id="twitter_link"
            v-model="formData.twitter_link"
            type="url"
            :label="$t('twitter_link') || 'twitter_link'"
          />

          <AppInput
            id="youtube_link"
            v-model="formData.youtube_link"
            type="url"
            :label="$t('youtube_link') || 'youtube_link'"
          />

          <AppInput
            id="instagram_link"
            v-model="formData.instagram_link"
            type="url"
            :label="$t('instagram_link') || 'instagram_link'"
          />
        </section>

        <hr />

        <!-- <section>
          <h4>{{ $t("home_sections") || "Home Sections" }}</h4>

          <AppInput
            id="awards_title_at_home"
            v-model="formData.awards_title_at_home"
            type="text"
            :label="$t('awards_title_at_home') || 'awards_title_at_home'"
          />

          <AppInput
            id="awards_title_at_home_ar"
            v-model="formData.awards_title_at_home_ar"
            type="text"
            :label="$t('awards_title_at_home_ar') || 'awards_title_at_home_ar'"
          />

          <AppTextarea
            id="home_brief_description"
            v-model="formData.home_brief_description"
            data-test="home_brief_description"
            rows="3"
            max-rows="8"
            :label="$t('home_brief_description') || 'Description ar'"
            :placeholder="$t('home_brief_description') || 'Description ar'"
          />

          <AppTextarea
            id="home_brief_description_ar"
            v-model="formData.home_brief_description_ar"
            data-test="home_brief_description_ar"
            rows="3"
            max-rows="8"
            :label="$t('home_brief_description_ar') || 'Description ar'"
            :placeholder="$t('home_brief_description_ar') || 'Description ar'"
          />
        </section> -->
        <hr />

        <section>
          <h4>{{ $t("address") || "Address" }}</h4>
          <AppTextarea
            id="address"
            v-model="formData.address"
            data-test="address"
            rows="3"
            max-rows="8"
            :label="$t('address') || 'address'"
            :placeholder="$t('address') || 'address'"
          />

          <AppTextarea
            id="address_ar"
            v-model="formData.address_ar"
            data-test="address_ar"
            rows="3"
            max-rows="8"
            :label="$t('address_ar') || 'address_ar'"
            :placeholder="$t('address_ar') || 'address_ar'"
          />

          <AppInput
            id="location"
            v-model="formData.location"
            :label="$t('location') || 'location'"
          />
        </section>
      </AppForm>
    </div>
    <GalleryModal id="gallary-modal" @select="selectImageHandler" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import {
  AppForm,
  AppInput,
  AppTextarea,
  AppButton,
} from "@/components/form/index";
import { toFormData, populateForm, isRTL } from "@/utils/index.js";
import GalleryModal from "@/components/UI/GalleryModal.vue";

export default {
  name: "SettingsForm",
  components: {
    AppForm,
    BCard,
    AppInput,
    AppTextarea,
    GalleryModal,
    AppButton,
  },
  data() {
    return {
      formData: {
        app_name: null,
        app_name_ar: null,
        meta_description: null,
        meta_description_ar: null,
        mobile: null,
        land_line: null,
        home_brief_description: null,
        home_brief_description_ar: null,
        logo_image_id: null,
        address: null,
        address_ar: null,
        awards_title_at_home: null,
        awards_title_at_home_ar: null,
        facebook_link: null,
        twitter_link: null,
        youtube_link: null,
        instagram_link: null,
        email: null,
        location: null,
      },
      successMessage: null,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.settings.isLoading.updating;
    },
    errorsBag() {
      return this.$store.state.settings.errors;
    },
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    settings() {
      return this.$store.state.settings.data;
    },
  },
  created() {
    this.$store.dispatch(`settings/fetchData`);
  },
  watch: {
    settings(val) {
      this.formData = val;
    },
  },
  mounted() {},
  methods: {
    selectImageHandler(image) {
      this.$set(this.formData, "logo_image_id", image);
    },
    openGallaryModal() {
      this.$bvModal.show("gallary-modal");
    },
    handleSubmit() {
      const data = toFormData(this.formData);

      this.$store.dispatch(`settings/updateData`, data).then(() => {
        this.$bvModal.hide(this.id);
        this.successMessage =
          this.$t("data_has_been_edited_successfully") ||
          "data has been edited Successfully";
      });
    },
    hideForm() {
      this.successMessage = null;
    },
    resetForm() {
      this.$store.dispatch("settings/resetModule");
      this.$store.dispatch("settings/resetMemberData");

      this.formDataEditMode = null;
    },
  },
};
</script>


<style lang="scss" scoped>
form {
  > * {
    margin-bottom: 1rem;
  }
}
</style>